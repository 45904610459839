import { NovaFilterKey } from 'lux/models/novaFilters';
import { useSelector } from 'lux/modules/useSelector';

import { useIsHeadHunterPlatform, useIsZarplataPlatform } from 'lux/hooks/usePlatform';

export type AdditionalFiltersOrderKey =
    | typeof NovaFilterKey.WorkTicket
    | typeof NovaFilterKey.DriverLicenseTypes
    | typeof NovaFilterKey.Language;
interface NovaFiltersOrders {
    filtersOrder: NovaFilterKey[];
    additionalFiltersOrder: AdditionalFiltersOrderKey[];
}

const useNovaFiltersOrderForResume = (): NovaFiltersOrders => {
    let filtersOrder: NovaFilterKey[] = [
        NovaFilterKey.Resume,
        NovaFilterKey.VacancyId,
        NovaFilterKey.Area,
        NovaFilterKey.District,
        NovaFilterKey.Metro,
        NovaFilterKey.Relocation,
        NovaFilterKey.JobSearchStatus,
        NovaFilterKey.Age,
        NovaFilterKey.Employment,
        NovaFilterKey.Schedule,
        NovaFilterKey.Experience,
        NovaFilterKey.ProfessionalRole,
        NovaFilterKey.Gender,
        NovaFilterKey.Salary,
        NovaFilterKey.Citizenship,
        NovaFilterKey.Label,
        NovaFilterKey.VerifiedSkills,
        NovaFilterKey.Skill,
        NovaFilterKey.Etc,
    ];
    let additionalFiltersOrder: AdditionalFiltersOrderKey[] = [
        NovaFilterKey.WorkTicket,
        NovaFilterKey.DriverLicenseTypes,
        NovaFilterKey.Language,
    ];

    const isPlatformZp = useIsZarplataPlatform();
    const isPlatformHH = useIsHeadHunterPlatform();

    if (isPlatformHH) {
        const relocationPos = filtersOrder.indexOf(NovaFilterKey.Relocation);
        const filtersWithExpIndustry = [...filtersOrder];
        filtersWithExpIndustry.splice(relocationPos + 1, 0, NovaFilterKey.FilterExpIndustry);
        filtersOrder = filtersWithExpIndustry;
    }

    if (isPlatformZp) {
        const uniqHhBasicFilters = [NovaFilterKey.Relocation, NovaFilterKey.Skill, NovaFilterKey.VerifiedSkills];

        filtersOrder = filtersOrder.filter((id) => !uniqHhBasicFilters.includes(id));

        const uniqHhAddFilters = [NovaFilterKey.Citizenship, NovaFilterKey.Language, NovaFilterKey.WorkTicket];
        additionalFiltersOrder = additionalFiltersOrder.filter((id) => !uniqHhAddFilters.includes(id));
    }

    if (isPlatformZp) {
        filtersOrder = filtersOrder.filter((id) => id !== NovaFilterKey.JobSearchStatus);
    }
    const searchOldEducation = useSelector((state) => state.searchOldEducation);
    if (!searchOldEducation) {
        const skillPosition = filtersOrder.indexOf(isPlatformZp ? NovaFilterKey.Salary : NovaFilterKey.Label);
        const filtersWithEducation = [...filtersOrder];
        filtersWithEducation.splice(skillPosition + 1, 0, NovaFilterKey.EducationLevel, NovaFilterKey.University);
        filtersOrder = filtersWithEducation;
    }
    return { filtersOrder, additionalFiltersOrder };
};

export default useNovaFiltersOrderForResume;
