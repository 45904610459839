import CustomSelect, { CustomSelectOption } from 'bloko/blocks/customSelect';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { NARROW_NON_BREAKING_SPACE } from 'lux/modules/symbols';

const TrlKeys = {
    resumes: 'employer.resumesSearch.resumes',
};

interface ItemsOnPageProps {
    onChange: (value: number) => void;
    value: number;
}

const ItemsOnPage: TranslatedComponent<ItemsOnPageProps> = ({ onChange, value, trls }) => {
    const values = [20, 50, 70, 100];

    if (!values.includes(value)) {
        values.push(value);
        values.sort((a, b) => Number(a) - Number(b));
    }

    return (
        <CustomSelect
            name="items_on_page"
            onChange={onChange}
            wrapperProps={{ 'data-qa': 'serp-settings__items-on-page' }}
            value={value}
            light
            flexible
        >
            {values.map((value) => (
                <CustomSelectOption data-qa={`items-on-page-${value}`} key={value} value={value}>
                    {value}
                    {NARROW_NON_BREAKING_SPACE}
                    {trls[TrlKeys.resumes]}
                </CustomSelectOption>
            ))}
        </CustomSelect>
    );
};

export default translation(ItemsOnPage);
