import { FC, PropsWithChildren } from 'react';

import { GridLayout } from '@hh.ru/magritte-ui';
import { ColumnsWrapper } from 'bloko/blocks/column';

import useMagritte from 'lux/hooks/useMagritte';

const GridWrapper: FC<PropsWithChildren> = ({ children, ...props }) => {
    const isMagritte = useMagritte();

    if (isMagritte) {
        return <GridLayout {...props}>{children}</GridLayout>;
    }

    return <ColumnsWrapper>{children}</ColumnsWrapper>;
};

export default GridWrapper;
