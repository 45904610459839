import { FC, PropsWithChildren } from 'react';

import { H3Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <H3Section Element="h1">{children}</H3Section>
            <VSpacing xs={6} base={3} />
        </>
    );
};

export default HeaderWrapper;
