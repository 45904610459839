import { FC } from 'react';

import { VSpacing, Text } from '@hh.ru/magritte-ui';

import LinkSecondary from 'lux/components/MagritteRedesignComponents/LinkSecondary';

import styles from 'lux/components/CatalogSearch/catalog-links.less';

export interface CatalogLink {
    path: string;
    title: string;
    name?: string;
}

interface CatalogLinksListProps {
    links: CatalogLink[];
    title: string;
    clickHandler?: (link: CatalogLink, index: number) => void;
}

const CatalogLinksList: FC<CatalogLinksListProps> = ({ title, links, clickHandler }) => {
    return (
        <>
            <Text typography="title-5-semibold">{title}</Text>
            <VSpacing default={8} />
            <ul className={styles.list}>
                {links.map((link, index) => (
                    <li key={link.path} className={styles.listItem}>
                        <LinkSecondary onClick={() => clickHandler?.(link, index)} href={link.path}>
                            {link.title}
                        </LinkSecondary>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default CatalogLinksList;
