import { useRef, useEffect } from 'react';

import Analytics, { VendorAnalytics } from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

const sendRegisterAnalytics = () => {
    VendorAnalytics.eventToStorage('employer', 'to_register', 'search_up_registration');
    Analytics.sendHHEventButtonClick('search_up_registration');
};

const TrlKeys = {
    register: 'resumesSearch.inSerp.register',
    benefitsForNotEmptyResult: 'resumesSearch.inSerp.benefits.notEmpty',
    benefitsForEmptyResult: 'resumesSearch.inSerp.benefits.empty',
    registerAction: 'resumesSearch.normalResults.register',
};

const RegistrationAnonActionInSerp: TranslatedComponent = ({ trls }) => {
    const regBannerRef = useRef<HTMLDivElement>(null);
    const registerURL = useSelectorNonNullable((state) => state.authUrl[`auth-employer-url`]);
    const stats = useSelector((state) => state.resumeSearchResult?.stats);

    useEffect(() => {
        if (regBannerRef.current) {
            Analytics.sendHHEventElementShown(regBannerRef.current, {
                name: 'show_registration_anon_action_in_serp',
            });
        }
    }, [regBannerRef]);

    if (stats.normalSearchResults === null || !registerURL) {
        return null;
    }

    const showedAfterRegistration = stats.normalSearchResults - stats.totalUngroupedResults;

    return (
        <div ref={regBannerRef} className="registration-in-serp">
            <div className="registration-in-serp-messages">
                <span className="registration-in-serp-icon" />
                <div>
                    <Text strong data-qa="resumes-total-found">
                        {trls[TrlKeys.register]}
                    </Text>
                    <VSpacing base={1} />
                    <Text data-qa="resumes-total-found">
                        {showedAfterRegistration === 0 && trls[TrlKeys.benefitsForEmptyResult]}
                        {showedAfterRegistration > 0 &&
                            format(trls[TrlKeys.benefitsForNotEmptyResult], {
                                '{0}': numberFormatter.format(`${showedAfterRegistration}`, {
                                    groupSeparator: NON_BREAKING_SPACE,
                                }),
                            })}
                    </Text>
                </div>
            </div>
            <div className="registration-in-serp-actions">
                <div className="registration-in-serp-actions-content">
                    <Button
                        Element={Link}
                        kind={ButtonKind.Primary}
                        onClick={sendRegisterAnalytics}
                        to={registerURL}
                        data-qa="resume-serp__action-after-search"
                    >
                        {trls[TrlKeys.registerAction]}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default translation(RegistrationAnonActionInSerp);
