import { useEffect, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Badge, Text, HSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { AdjustmentsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import throttle from 'bloko/common/throttle';

import FloatingButton from 'lux/components/FloatingButton';
import SearchType from 'lux/components/NovaFilters/SearchType';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import { useSelector } from 'lux/modules/useSelector';

import useToggleSearchPreference from 'lux/components/SearchSorts/hooks/useToggleSearchPreference';

const SCROLL_THROTTLE_DELAY_MS = 100;

const TrlKeys = {
    buttonFilter: 'search.vacancy.button.filters.s',
    buttonFilterXS: 'novafilters.similarSavedSearch.filterXS',
};

const SearchPreferenceButton: TranslatedComponent = ({ trls }) => {
    const isMagritte = useMagritte();

    const showSearchPreference = useSelector((state) => state.searchPreference.isShown);
    const enableSimilarSavedSearch = useSelector((state) => state.vacancySearchResult.enableSimilarSavedSearch);

    const toggleSearchPreference = useToggleSearchPreference();
    const searchType = useSelector((state) => state.searchClustersSettings.type);

    let totalUsedVacancyFilters = useSelector((state) => state.vacancySearchResult.totalUsedFilters) || 0;

    // TODO: перенести расчет этих параметров на бэкенд https://jira.hh.ru/browse/HH-223905 после эксперимента
    const criteriaText = useSelector((state) => state.vacancySearchResult.criteria?.text);
    const resume = useSelector((state) => state.vacancySearchResult.criteria?.resume);
    totalUsedVacancyFilters = isMagritte && criteriaText ? totalUsedVacancyFilters - 1 : totalUsedVacancyFilters;
    totalUsedVacancyFilters = isMagritte && !!resume ? totalUsedVacancyFilters + 1 : totalUsedVacancyFilters;

    const totalUsedResumeFilters = useSelector((state) => state.resumeSearchResult.totalUsedFilters) || 0;

    const totalUsedFilters = searchType === SearchType.Resume ? totalUsedResumeFilters : totalUsedVacancyFilters;

    const [isHiddenLabel, setIsHiddenLabel] = useState(false);

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (!isMagritte || !isMobile) {
            return undefined;
        }

        const checkScroll = throttle(() => {
            setIsHiddenLabel(window.scrollY > 0);
        }, SCROLL_THROTTLE_DELAY_MS);

        window.addEventListener('scroll', checkScroll);

        return () => window.removeEventListener('scroll', checkScroll);
    }, [isMagritte, isMobile]);

    if (isMagritte && isMobile) {
        return (
            <FloatingButton
                onClick={() => {
                    Analytics.sendHHEventButtonClick(`${searchType}_search_filter_open`);
                    toggleSearchPreference(!showSearchPreference);
                }}
            >
                <AdjustmentsOutlinedSize24 initial="contrast" />
                {!isHiddenLabel && (
                    <>
                        <HSpacing default={12} />
                        <Text Element="span" typography="subtitle-1-semibold" style="contrast">
                            {trls[TrlKeys.buttonFilter]}
                        </Text>
                    </>
                )}
                {totalUsedFilters > 0 && (
                    <>
                        <HSpacing default={12} />
                        <Badge>{`${totalUsedFilters}`}</Badge>
                    </>
                )}
            </FloatingButton>
        );
    }

    return (
        <div className="vacancy-serp-sticky-filters-button">
            <Button
                onClick={() => {
                    Analytics.sendHHEventButtonClick(`${searchType}_search_filter_open`);
                    toggleSearchPreference(!showSearchPreference);
                }}
                kind={ButtonKind.Primary}
            >
                {enableSimilarSavedSearch ? trls[TrlKeys.buttonFilterXS] : trls[TrlKeys.buttonFilter]}
            </Button>
        </div>
    );
};

export default translation(SearchPreferenceButton);
