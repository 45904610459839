// tempexp_28457_file, tempexp_30305_file
import { FC } from 'react';

import styles from './styles.less';

export enum RatingValue {
    Sad = '1',
    Halfsad = '2',
    Neutral = '3',
    Halfsmile = '4',
    Smile = '5',
}

const ratingValues = Object.values(RatingValue);

interface RatingProps {
    name: string;
    onChange: (value: RatingValue) => void;
}

const stylesMapping: Record<RatingValue, string> = {
    [RatingValue.Sad]: styles.ratingLabelSad,
    [RatingValue.Halfsad]: styles.ratingLabelHalfsad,
    [RatingValue.Neutral]: styles.ratingLabelNeutral,
    [RatingValue.Halfsmile]: styles.ratingLabelHalfsmile,
    [RatingValue.Smile]: styles.ratingLabelSmile,
};

const Rating: FC<RatingProps> = ({ name, onChange }) => {
    return (
        <div>
            {ratingValues.map((item) => (
                <label key={item} className={`${styles.ratingLabel} ${stylesMapping[item]}`}>
                    <input
                        type="radio"
                        name={name}
                        value={item}
                        className={styles.ratingCheckbox}
                        onChange={() => {
                            onChange(item);
                        }}
                    />
                </label>
            ))}
        </div>
    );
};

export default Rating;
