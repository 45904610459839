import { useEffect } from 'react';

import SearchType from 'lux/components/NovaFilters/SearchType';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';
import { UXFeedback } from 'lux/utils/uxfeedback';

import useMagritte from 'lux/hooks/useMagritte';
import { useIsRabotaBy } from 'lux/hooks/useSites';

const excludingUserType = {
    [SearchType.Resume]: UserType.Applicant,
    [SearchType.Vacancy]: UserType.Employer,
};

export default (searchType: SearchType.Resume | SearchType.Vacancy): void => {
    const isMagritte = useMagritte();
    const isRabotaBy = useIsRabotaBy();
    const userType = useSelector((state) => state.userType);
    const searchRating = useSelector((state) => state.searchRating);
    const showSearchQualityUxFb = !isRabotaBy && !searchRating && userType !== excludingUserType[searchType];
    const { enableSimilarSavedSearch } = useSelector((state) => state.vacancySearchResult);
    useEffect(() => {
        if (!showSearchQualityUxFb) {
            return;
        }
        /* Possible values:
         * Resume search:
         * - resume_search_quality_anonymous
         * - resume_search_quality_employer
         * Vacancy search:
         * - vacancy_search_quality_anonymous
         * - vacancy_search_quality_applicant
         * - recommended_resume_search */
        let eventName = `${searchType}_search_quality_${userType}`;
        if (enableSimilarSavedSearch) {
            eventName = 'recommended_resume_search';
        }
        /* Possible values
         * Resume search:
         *  - resume_search_quality_anonymous_magritte
         *  - resume_search_quality_employer_magritte
         * Vacancy search:
         *  - vacancy_search_quality_anonymous_magritte
         *  - vacancy_search_quality_applicant_magritte
         *  - recommended_resume_search_magritte */
        if (isMagritte) {
            eventName = `${eventName}_magritte`;
        }
        UXFeedback.sendEvent(eventName);
    }, [userType, showSearchQualityUxFb, searchType, enableSimilarSavedSearch, isMagritte]);
};
