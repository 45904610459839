import { FC, useEffect, useState } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { ArrowUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { ArrowScaleSmallKindUp } from 'bloko/blocks/icon';
import scrollTop from 'bloko/common/scrollTop';
import throttle from 'bloko/common/throttle';

import FloatingButton from 'lux/components/FloatingButton';
import useMagritte from 'lux/hooks/useMagritte';
import useOnOffState from 'lux/hooks/useOnOffState';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';
import { isHtmlElement } from 'lux/utils/isHtmlElement';

import styles from './scroll-to-top.less';

const SCROLL_THROTTLE_DELAY_MS = 100;
const SCROLL_TO_TOP_SPEED_MS = 150;
const CHECKPOINT_SHOW_BUTTON_RATIO = 0.5;

/**
 * Свойства компонента.
 *
 * @prop analyticsParams Параметры аналитики.
 * @prop [forceHidden] Флаг принудительного скрытия кнопки для ручного управления из родителя.
 */
type Props = {
    analyticsParams: Record<string, unknown>;
    forceHidden?: boolean;
};

/**
 * Следящая кнопка для скролла вверх на страницах поиска.
 */
const ScrollToTop: FC<Props> = ({ analyticsParams, forceHidden }) => {
    const userType = useSelector((state) => state.userType);

    const [shown, setShown] = useState<boolean>(false);
    const [isScrollingToTop, scrollingToTopStart, scrollingToTopEnd] = useOnOffState(false);

    const isHhPlatform = useIsHeadHunterPlatform();

    const { isMobile } = useBreakpoint();
    const isMagritte = useMagritte();

    useEffect(() => {
        if (!isHhPlatform) {
            return undefined;
        }

        const handleScroll = throttle(
            () =>
                setShown(
                    !forceHidden &&
                        !isScrollingToTop &&
                        window.scrollY > CHECKPOINT_SHOW_BUTTON_RATIO * window.innerHeight
                ),
            SCROLL_THROTTLE_DELAY_MS
        );

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [forceHidden, isHhPlatform, isScrollingToTop]);

    useEffect(() => {
        if (forceHidden) {
            setShown(false);
        }
    }, [forceHidden]);

    if (!isHhPlatform) {
        return null;
    }

    const handleClick = () => {
        const isPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        const genericScrollToTopSpeed = isPrefersReducedMotion ? 0 : SCROLL_TO_TOP_SPEED_MS;

        scrollTop({
            top: 0,
            speed: genericScrollToTopSpeed,
            animationStartCallback: scrollingToTopStart,
            animationEndCallback: scrollingToTopEnd,
        });
        isHtmlElement(document.activeElement) && document.activeElement.blur();
        setShown(false);
        Analytics.sendHHEventButtonClick('scrollToTop', {
            userType,
            scrollPosition: window.scrollY,
            contentHeight: document.body.clientHeight,
            ...analyticsParams,
        });
    };

    if (isMagritte) {
        return (
            <div
                className={classnames(styles.scrollToTopMagritteContainer, {
                    [styles.scrollToTopMagritteRight]: !isMobile,
                    [styles.shown]: shown,
                    [styles.hidden]: forceHidden,
                })}
            >
                <FloatingButton onClick={handleClick}>
                    <ArrowUpOutlinedSize24 initial="contrast" />
                </FloatingButton>
            </div>
        );
    }

    return (
        <div
            className={classnames(styles.scrollToTop, {
                [styles.scrollToTopShown]: shown,
                [styles.scrollToTopForceHidden]: forceHidden,
            })}
        >
            <button className={styles.scrollToTopButton} onClick={handleClick}>
                <ArrowScaleSmallKindUp />
            </button>
        </div>
    );
};

export default ScrollToTop;
