import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { Button } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SearchType from 'lux/components/NovaFilters/SearchType';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    text: 'search.filters.reset',
};

const searchCountsAction = makeSetStoreField('searchCounts');

const ResetButton: TranslatedComponent = ({ trls }) => {
    const actionPath = useSelector((state) => state.searchClustersSettings.actionPath);
    const searchCounts = useSelector((state) => state.searchCounts);
    const searchType = useSelector((state) => state.searchClustersSettings.type);

    const dispatch = useDispatch();

    return (
        <Button
            style="accent"
            mode="secondary"
            onClick={() => {
                if (searchType === SearchType.Vacancy) {
                    dispatch(searchCountsAction({ isLoad: true, value: searchCounts.value }));
                }
                dispatch(push(actionPath));
            }}
            stretched
        >
            {trls[TrlKeys.text]}
        </Button>
    );
};

export default translation(ResetButton);
