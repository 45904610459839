import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { additionalCheckArticleLink } from 'lux/components/Employer/AdditionalCheck';
import translation from 'lux/components/translation';

const TrlKeys = {
    checkIsRunning: 'additionalCheck.checkIsRunning',
    restrictions: 'additionalCheck.restrictions',
    dueDate: 'additionalCheck.dueDate',
    infoButton: 'additionalCheck.infoButton',
};

const AdditionalCheckSnippet: TranslatedComponent = ({ trls }) => {
    return (
        <>
            <H3>{trls[TrlKeys.checkIsRunning]}</H3>
            <VSpacing base={4} />
            <p>{trls[TrlKeys.restrictions]}</p>
            <VSpacing base={2} />
            <p>{trls[TrlKeys.dueDate]}</p>
            <VSpacing base={4} />
            <Button
                to={additionalCheckArticleLink}
                Element={Link}
                appearance={ButtonAppearance.Outlined}
                data-qa="Additional-check-snippet-button"
            >
                {trls[TrlKeys.infoButton]}
            </Button>
            <HSpacing base={7} />
        </>
    );
};

export default translation(AdditionalCheckSnippet);
