import { FC } from 'react';

import GetPageHtml from 'lux/components/GetPageHtml/GetPageHtml.obfuscate';
import BuyContacts from 'lux/components/ResumeSearch/BuyContacts';
import QueryFixer from 'lux/components/ResumeSearch/QueryFixer';
import QuerySuggests from 'lux/components/ResumeSearch/QuerySuggests';
import RegistrationAction from 'lux/components/ResumeSearch/RegistrationAction';

const ResumeSearchTop: FC = () => {
    return (
        <>
            <BuyContacts />
            <QueryFixer />
            <QuerySuggests />
            <RegistrationAction />
            <GetPageHtml />
        </>
    );
};

export default ResumeSearchTop;
