import {
    CrossScaleSmallEnclosedFalse,
    LupaScaleSmallKindDefaultAppearanceOutlined,
    IconDynamic,
    IconLink,
    IconColor,
} from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'filter.clear.icon.title',
};

interface FilterIconProps {
    isLoading: boolean;
    value: string;
    handleClear: () => void;
}
const FilterIcon: TranslatedComponent<FilterIconProps> = ({ isLoading, value, handleClear, trls }) => {
    let filterIcon = <LupaScaleSmallKindDefaultAppearanceOutlined initial={IconColor.Gray50} />;

    if (value) {
        filterIcon = (
            <IconDynamic>
                <IconLink Element="button" type="button" title={trls[TrlKeys.title]} onClick={handleClear}>
                    <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} highlighted={IconColor.Gray60} />
                </IconLink>
            </IconDynamic>
        );
    }

    if (isLoading) {
        filterIcon = <Loading scale={LoadingScale.Small} />;
    }

    return filterIcon;
};

export default translation(FilterIcon);
