import urlParser from 'bloko/common/urlParser';

import useUrl from 'lux/hooks/useUrl';

export const CONTEXT_VACANCY_ID_NOT_CHOSEN = -1;
export const CONTEXT_VACANCY_ID_PARAM = 'context_vacancy_id';

export const useContextVacancyIdFromUrl = (): number => {
    const url = useUrl();
    const parsedUrl = urlParser(url);

    return Number(parsedUrl.params[CONTEXT_VACANCY_ID_PARAM] ?? CONTEXT_VACANCY_ID_NOT_CHOSEN);
};
