import PropTypes from 'prop-types';

import { formatNewLine } from 'bloko/common/trl';

import Attention, { AttentionKind } from 'lux/components/Attention';
import PageErrorWrapper from 'lux/components/PageErrorWrapper';
import { DefaultLayout } from 'lux/components/PageLayout';
import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

const ErrorWrapper = ({ children, trls }) => {
    const errorCode = useSelector((state) => state.errorCode);
    const userType = useSelector((state) => state.userType);

    if (errorCode === 403 && userType === UserType.Employer) {
        return (
            <DefaultLayout>
                <Attention kind={AttentionKind.Bad} corrected>
                    {formatNewLine(trls[ErrorWrapper.trls.denied])}
                </Attention>
            </DefaultLayout>
        );
    }
    return <PageErrorWrapper>{children}</PageErrorWrapper>;
};

ErrorWrapper.trls = {
    denied: 'employer.resumesSearch.denied',
};

ErrorWrapper.propTypes = {
    children: PropTypes.node,
    trls: PropTypes.object,
};

export default translation(ErrorWrapper);
