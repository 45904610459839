import { FC, PropsWithChildren } from 'react';

import { Link, LinkProps, Text } from '@hh.ru/magritte-ui';

import styles from './link-secondary.less';

interface CustomLinkProps extends Omit<LinkProps, 'Element'> {
    Element?: 'a';
}

const LinkSecondary: FC<CustomLinkProps & PropsWithChildren & { onClick: () => void }> = ({
    typography = 'label-3-regular',
    children,
    Element = 'a',
    ...props
}) => (
    <Link Element={Element} {...props}>
        <span className={styles.linkSecondary}>
            <Text style="secondary" typography={typography}>
                {children}
            </Text>
        </span>
    </Link>
);
export default LinkSecondary;
