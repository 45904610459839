import { FC } from 'react';

import { useElementShown } from '@hh.ru/analytics-js';
import vacancySearchQuickFilterApplyButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_filter/vacancy_search_quick_filter_apply_button_click';
import vacancySearchQuickFilterListElementShown from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_filter/vacancy_search_quick_filter_list_element_shown';
import { VSpacing } from '@hh.ru/magritte-ui';
import { Link as ReduxLink } from '@hh.ru/redux-spa-middleware';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import BlokoVSpacing from 'bloko/blocks/vSpacing';

import useMagritte from 'lux/hooks/useMagritte';

import CatalogLinksList, { CatalogLink } from 'lux/components/CatalogSearch/CatalogLinksList';

interface CatalogLinksProps {
    links: CatalogLink[];
    title: string;
    hhtmSourceLabel: 'TOP_FILTER_CATALOG' | 'BOTTOM_FILTER_CATALOG' | 'BOTTOM_SIMILAR_QUERY';
}

const CatalogLinks: FC<CatalogLinksProps> = ({ links, title, hhtmSourceLabel }) => {
    const isMagritte = useMagritte();
    const showLinksContainerRef = useElementShown(vacancySearchQuickFilterListElementShown, {
        filterList: links.map(({ name }) => name).join(', '),
        hhtmSourceLabel,
    });

    const onCatalogLinkClick = (link: CatalogLink, index: number) =>
        vacancySearchQuickFilterApplyButtonClick({
            position: index + 1,
            filter: link.name || '',
            hhtmSourceLabel,
            hhtmSource: 'vacancy_search_list',
        });

    if (isMagritte) {
        return (
            <>
                <VSpacing default={40} />
                <div ref={showLinksContainerRef}>
                    <CatalogLinksList links={links} title={title} clickHandler={onCatalogLinkClick} />
                </div>
            </>
        );
    }

    return (
        <>
            <BlokoVSpacing base={12} />
            <div ref={showLinksContainerRef}>
                <H3Section>{title}</H3Section>
                <BlokoVSpacing base={4} />
                <ul className="multiple-column-list multiple-column-list_narrow">
                    {links.map((link, index) => (
                        <li key={link.path} className="catalog__item">
                            <BlokoLink
                                disableVisited
                                kind={LinkKind.Secondary}
                                onClick={() => onCatalogLinkClick(link, index)}
                                Element={ReduxLink}
                                isSeoLink
                                to={link.path}
                            >
                                <span>{link.title}</span>
                            </BlokoLink>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default CatalogLinks;
