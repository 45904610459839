import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './index.less';

const TrlKeys = {
    title: 'search.resume.autoInviteSnippet.title',
    description: 'search.resume.autoInviteSnippet.description',
    button: 'search.resume.autoInviteSnippet.button',
};

const AutoInviteSnippet: TranslatedComponent = ({ trls }) => {
    const isAutoInvitePromo = useSelector((state) => state.resumeSearchAutoInvitePromo);
    const vacancyId = useSelector((state) => state.router.location.query.vacancy_id);

    if (!isAutoInvitePromo) {
        return null;
    }

    return (
        <>
            <div className={styles.container}>
                <div>
                    <Text strong>{trls[TrlKeys.title]}</Text>
                    <VSpacing base={2} />
                    <Text>{trls[TrlKeys.description]}</Text>
                </div>
                <Button
                    Element={Link}
                    kind={ButtonKind.Success}
                    to={`/employer/vacancyresponses/autoinvite?vacancyId=${vacancyId}&active=true`}
                    target="_blank"
                >
                    {trls[TrlKeys.button]}
                </Button>
            </div>
            <VSpacing base={6} />
        </>
    );
};

export default translation(AutoInviteSnippet);
